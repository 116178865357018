export default class NetworkManager {
    static API: string = 'https://dn7rz85an7.execute-api.ap-south-1.amazonaws.com/dev'

    static async fetch(url: string, payload: any): Promise<any> {
        try {
            const res = await this.fetchCall(url, payload)
            let resJson = await res.json()
            if (resJson.message) {
                throw new Error(resJson.message)
            }
            return resJson
        } catch (e) {}
    }

    static async fetchVoid(url: string, payload: any): Promise<void> {
        try {
            await this.fetchCall(url, payload)
        } catch (e) {}
    }

    static async fetchCall(url: string, payload: any): Promise<any> {
        return await fetch(NetworkManager.API + url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...payload,
            }),
        })
    }
}
