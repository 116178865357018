import React, {useState} from 'react'
import Test from '../../types/Test'
import {MCQ} from '../../types/QuestionContent'
import Score from './Score'
import {useHistory} from 'react-router-dom'
import AnswerKey from './AnswerKey'
// @ts-ignore
import MathJax from 'react-mathjax2'

interface Props {
    testData: Test
    setTestData: (testData: any) => void
}

export default function (props: Props) {
    const [page, setPage] = useState('score')
    const history = useHistory()

    if (!(props.testData && props.testData.questionList)) {
        history.push('/home')
    }

    let score: number = 0
    let correctCount: number = 0
    let incorrectCount: number = 0
    let unansweredCount: number = 0
    let questionList = []
    for (let i = 0; i < props.testData.questionList.length; i++) {
        if (props.testData.questionInstanceList[i].selectedAnswer === '') {
            unansweredCount++
        } else if (props.testData.questionList[i].answer === props.testData.questionInstanceList[i].selectedAnswer) {
            correctCount++
            score += 4
        } else {
            incorrectCount++
            score--
        }
        questionList.push({
            question: (props.testData.questionList[i].content as MCQ).question,
            answer: props.testData.questionList[i].answer,
            selectedAnswer: props.testData.questionInstanceList[i].selectedAnswer,
            supportImage: (props.testData.questionList[i].content as MCQ).supportImage,
        })
    }

    if (page === 'score') {
        return (
            <Score
                score={score}
                setPage={() => {
                    setPage('questions')
                }}
                setHistory={(page: string) => {
                    history.push(page)
                }}
                correctCount={correctCount}
                incorrectCount={incorrectCount}
                unansweredCount={unansweredCount}
            />
        )
    } else {
        return <AnswerKey setPage={setPage} questionList={questionList} />
    }
}
