import React from 'react'
import NavButtons from './NavButtons'
import AnswerBlock from './AnswerBlock'
import QuestionBlock from './QuestionBlock'
import SupportImageBlock from './SupportImageBlock'
import {isDesktop} from 'react-device-detect'
import {ThemeColors} from '../../../Constants'

interface Props {
    setPage: (page: string) => void
    questionList: any[]
}

export default function (props: Props) {
    const styles = isDesktop ? stylesDesktop : stylesMobile

    return (
        <div style={styles.container}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}>
                <h1 style={styles.heading}>ANSWER KEY</h1>
                <NavButtons setPage={props.setPage} />
            </div>
            <div style={styles.questionList}>
                {props.questionList.map((question, index) => (
                    <div style={styles.questionReviewContainer}>
                        <QuestionBlock question={question.question} index={index} />
                        <SupportImageBlock supportImage={question.supportImage} />
                        <div style={styles.answerBlock}>
                            <AnswerBlock answer={question.answer} text={'Correct'} />
                            {question.selectedAnswer && question.selectedAnswer.length ? (
                                <AnswerBlock answer={question.selectedAnswer} text={'Your'} />
                            ) : (
                                <div />
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

const stylesDesktop = {
    container: {
        backgroundImage: 'url(./result/key/bg.png)',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
    },
    heading: {
        fontSize: '5vw',
        marginLeft: '3vw',
        marginRight: '3vw',
        paddingTop: '1.2vw',
        borderBottom: '5px solid ' + ThemeColors.themePrimary,
        width: 'fit-content',
        color: 'white',
        fontFamily: "'Teko', sans-serif",
    },
    questionReviewContainer: {
        display: 'flex',
        flexDirection: 'column' as 'column',
    },
    answerBlock: {
        display: 'flex',
        flexDirection: 'row' as 'row',
    },
    questionList: {
        padding: '3vw',
        paddingTop: 0,
    },
}

const stylesMobile = {
    container: {
        backgroundImage: 'url(./result/key/bg-mobile.png)',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        backgroundColor: ThemeColors.themeSecondary,
    },
    heading: {
        fontSize: '8vh',
        marginLeft: '3vh',
        paddingTop: '1.2vh',
        borderBottom: '5px solid ' + ThemeColors.themePrimary,
        width: 'fit-content',
        color: 'white',
        fontFamily: "'Teko', sans-serif",
    },
    questionReviewContainer: {
        display: 'flex',
        flexDirection: 'column' as 'column',
    },
    answerBlock: {
        display: 'flex',
        flexDirection: 'column' as 'column',
    },
    questionList: {
        padding: '3vh',
        paddingTop: 0,
    },
}
