import React from 'react'
import ReactLoading from 'react-loading'
import {ThemeColors} from '../../Constants'

export default function () {
    return (
        <div style={styles.bg}>
            <ReactLoading type={'spin'} color={ThemeColors.themePrimary} height={100} width={100} />
        </div>
    )
}

const styles = {
    bg: {
        backgroundColor: ThemeColors.themeSecondary,
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}
