import Question from '../types/Question'
import NetworkManager from '../infrastructure/NetworkManager'

class InternalService {
    async fetchQuestions(offset: number, length: number): Promise<Question[]> {
        return NetworkManager.fetch('/internal/questions/fetch', {offset, length})
    }
}

export default new InternalService()
