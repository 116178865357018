import QuestionInstance from './QuestionInstance'
import Question from './Question'
import RawTest from './RawTest'
import TestResult from './TestResult'

export default interface Test {
    id: string
    score: number
    timeTaken: number
    questionList: Question[]
    questionInstanceList: QuestionInstance[]
    timestamp: number
}

export class TestManipulator {
    static createNewTestInstance(rawTest: RawTest): Test {
        const questionInstanceList: QuestionInstance[] = rawTest.questionList.map(
            (question: Question): QuestionInstance => ({
                questionId: question.id,
                correct: false,
                timeTaken: 0,
                selectedAnswer: '',
            }),
        )
        return {
            id: rawTest.id,
            score: 0,
            timeTaken: 0,
            timestamp: 0,
            questionList: rawTest.questionList,
            questionInstanceList,
        }
    }

    static transformToResult = (test: Test): TestResult => ({
        id: test.id,
        timestamp: 0,
        questionInstanceList: test.questionInstanceList,
        timeTaken: test.questionInstanceList
            .map((questionInstance: QuestionInstance): number => questionInstance.timeTaken)
            .reduce((acc: number, curr: number) => acc + curr),
        score: test.questionInstanceList
            .map((questionInstance: QuestionInstance): number => (questionInstance.correct ? 1 : 0))
            .reduce((acc: number, curr: number) => acc + curr),
    })
}
