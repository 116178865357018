import React from 'react'
// @ts-ignore
import {QuestionManipulator} from '../../../types/Question'
import {isDesktop} from 'react-device-detect'

interface Props {
    question: string
    index: number
}

export default function (props: Props) {
    const styles = isDesktop ? stylesDesktop : stylesMobile

    return (
        <div style={styles.questionBlock}>
            <div style={styles.questionNumber}>{props.index + 1 + '. '}</div>
            <div style={styles.question}>{QuestionManipulator.formatText(props.question)}</div>
        </div>
    )
}

const stylesDesktop = {
    questionBlock: {
        display: 'flex',
        flexDirection: 'row' as 'row',
        marginBottom: '1.2vw',
        marginTop: '3vw',
    },
    question: {
        fontSize: '1.5vw',
        color: 'white',
    },
    questionNumber: {
        fontSize: '1.5vw',
        fontWeight: 800,
        color: 'white',
        marginRight: '0.6vw',
    },
}

const stylesMobile = {
    questionBlock: {
        display: 'flex',
        flexDirection: 'row' as 'row',
        marginBottom: '3vh',
        marginTop: '6vh',
    },
    question: {
        fontSize: '2vh',
        color: 'white',
    },
    questionNumber: {
        fontSize: '2vh',
        fontWeight: 800,
        color: 'white',
        marginRight: '0.6vh',
    },
}
