import React from 'react'
import Question from '../../types/Question'
import QuestionInstance from '../../types/QuestionInstance'
import MCQRenderer from './QuestionRenderers/MCQRenderer'
import AssertionReasonRenderer from './QuestionRenderers/AssertionReasonRenderer'

interface Props {
    question: Question
    questionInstance: QuestionInstance
    selectAnswer: (answer: string) => void
}

export default function (props: Props) {
    if (props.question.typeId === 'MCQ' || 1) {
        return (
            <MCQRenderer
                question={props.question}
                questionInstance={props.questionInstance}
                selectAnswer={props.selectAnswer}
            />
        )
    } else if (props.question.typeId === 'AR') {
        return (
            <AssertionReasonRenderer
                question={props.question}
                questionInstance={props.questionInstance}
                selectAnswer={props.selectAnswer}
            />
        )
    } else {
        return <div />
    }
}
