import React from 'react'
import {ThemeColors} from '../../../Constants'

interface Props {
    score: number
    correctCount: number
    incorrectCount: number
    unansweredCount: number
    setPage: () => void
    setHistory: (page: string) => void
}

export default function (props: Props) {
    return (
        <div style={styles.container}>
            <div style={styles.scoreBlock}>
                <div style={styles.heading}>You Scored</div>
                <div style={styles.scoreContainer}>
                    <div style={styles.score}>{props.score.toString() + ' / '}</div>
                    <div style={styles.total}> 360</div>
                </div>
                <div style={styles.buttonContainer}>
                    <button onClick={props.setPage} style={styles.button}>
                        Answer Key
                    </button>
                    <button
                        onClick={() => {
                            props.setHistory('/')
                        }}
                        style={styles.button}>
                        Back to Home
                    </button>
                </div>
            </div>
            <div style={styles.statusBlock}>
                <div style={styles.statusContainer}>
                    <div style={styles.statusHeading}>CORRECT : </div>
                    <div style={styles.status}>{props.correctCount}</div>
                </div>
                <div style={styles.statusContainer}>
                    <div style={styles.statusHeading}>INCORRECT : </div>
                    <div style={styles.status}>{props.incorrectCount}</div>
                </div>
                <div style={styles.statusContainer}>
                    <div style={styles.statusHeading}>UNANSWERED : </div>
                    <div style={styles.status}>{props.unansweredCount}</div>
                </div>
            </div>
        </div>
    )
}

const styles = {
    scoreBlock: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        flex: 1,
        height: '100%',
    },
    container: {
        padding: 0,
        height: '100%',
        width: '100%',
        backgroundImage: 'url(./result/score/bg.png)',
        backgroundSize: 'cover',
        display: 'flex',
        flexDirection: 'row' as 'column',
        flex: 1,
        justifyContent: 'space-evenly',
        alignItems: 'center',
    },
    heading: {
        color: 'white',
        fontSize: '5vw',
        letterSpacing: '0.6vw',
        fontFamily: "'Ubuntu', sans-serif",
        marginTop: '2vw',
    },
    scoreContainer: {
        display: 'flex',
        flexDirection: 'row' as 'row',
        alignItems: 'flex-end',
    },
    score: {
        color: 'white',
        fontSize: '5vw',
        fontWeight: 800,
    },
    total: {
        color: 'white',
        fontSize: '3vw',
        fontWeight: 800,
        paddingLeft: '0.6vw',
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row' as 'row',
    },
    button: {
        background: ThemeColors.themePrimary,
        border: 'none',
        borderRadius: 5,
        color: 'white',
        paddingTop: '1vw',
        paddingBottom: '1vw',
        paddingLeft: '2vw',
        paddingRight: '2vw',
        marginTop: '2.4vw',
        marginRight: '2.4vw',
        fontSize: '1.2vw',
        fontWeight: 800,
        fontFamily: "'Ubuntu', sans-serif",
    },
    statusContainer: {
        display: 'flex',
        flexDirection: 'row' as 'row',
        alignItems: 'baseline' as 'baseline',
        marginBottom: '5vh',
    },
    statusHeading: {
        color: 'white',
        fontSize: '2.4vw',
        letterSpacing: 3,
        fontWeight: 600,
        fontFamily: "'Ubuntu', sans-serif",
    },
    status: {
        color: 'white',
        fontSize: '2.4vw',
        fontWeight: 800,
        marginLeft: '0.6vw',
    },
    statusBlock: {
        display: 'flex',
        flex: 1,
        marginTop: 50,
        flexDirection: 'column' as 'column',
        justifyContent: 'space-evenly',
    },
}
