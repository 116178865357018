import React, {useState} from 'react'
import './App.css'
import Landing from './views/landing'
import TestScreen from './views/test/TestScreen'
import TestResult from './views/result/TestResult'
import Questions from './views/internal/Questions'
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'

function App() {
    const [mobileNumber, setMobileNumber] = useState('')
    const [name, setName] = useState('')
    const [testData, setTestData] = useState(null)

    document.title = 'NeetBio'
    document.body.style.overflow = 'auto'
    // @ts-ignore
    document.getElementsByTagName('META')[2].content = 'Free NEET Biology Model Exams Online'

    return (
        <Router>
            <Switch>
                <Route path={'/test'}>
                    <TestScreen
                        name={name}
                        // @ts-ignore
                        testData={testData}
                        setTestData={setTestData}
                        mobileNumber={mobileNumber}
                    />
                </Route>
                <Route path={'/result'}>
                    {/* @ts-ignore */}
                    <TestResult testData={testData} setTestData={setTestData} />
                </Route>
                <Route path={'/internal'}>
                    <Questions />
                </Route>
                <Route path={'/'}>
                    <Landing
                        name={name}
                        mobileNumber={mobileNumber}
                        setName={setName}
                        setMobileNumber={setMobileNumber}
                        setTestData={setTestData}
                    />
                </Route>
            </Switch>
        </Router>
    )
}

export default App
