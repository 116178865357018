import * as React from 'react'
import QuestionInstance from '../../../types/QuestionInstance'
import NextButton from './NextButton'
import PreviousButton from './PreviousButton'
import SubmitButton from './SubmitButton'
import {isDesktop} from 'react-device-detect'

interface Props {
    questionInstanceList: QuestionInstance[]
    currentQuestionNumber: number
    nextQuestion: () => void
    previousQuestion: () => void
    submitTest: () => void
}

export default function (props: Props) {
    const styles = isDesktop ? stylesDesktop : stylesMobile
    return (
        <div style={styles.container}>
            <div style={styles.subContainer}>
                <PreviousButton currentQuestionNumber={props.currentQuestionNumber} previousQuestion={props.previousQuestion} />
                <NextButton
                    questionInstanceList={props.questionInstanceList}
                    currentQuestionNumber={props.currentQuestionNumber}
                    nextQuestion={props.nextQuestion}
                />
            </div>
            {isDesktop ? <SubmitButton submitTest={props.submitTest} /> : <div />}
        </div>
    )
}

const stylesDesktop = {
    container: {
        paddingLeft: '5vw',
        paddingRight: '5vw',
        marginTop: '0.5vw',
        display: 'flex',
        flex: 1,
        flexDirection: 'row' as 'row',
        justifyContent: 'space-between',
        width: '100%',
    },
    subContainer: {},
}

const stylesMobile = {
    container: {
        paddingLeft: '3vh',
        paddingRight: '3vh',
        paddingBottom: '4vh',
        marginTop: '1vh',
        display: 'flex',
        flex: 1,
        flexDirection: 'row' as 'row',
        justifyContent: 'space-between',
        width: '100%',
    },
    subContainer: {
        display: 'flex',
        height: 'fit-content' as 'fit-content',
        marginTop: '4vh',
        width: '100%',
        flexDirection: 'row' as 'row',
        justifyContent: 'space-between',
    },
}
