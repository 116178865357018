import * as React from 'react'
import {isDesktop} from 'react-device-detect'
import {ThemeColors} from '../../../Constants'

interface Props {
    currentQuestionNumber: number
    previousQuestion: () => void
}

export default function (props: Props) {
    const styles = isDesktop ? stylesDesktop : stylesMobile
    return (
        <button style={styles.button} onClick={props.previousQuestion} disabled={props.currentQuestionNumber === 0}>
            Back
        </button>
    )
}

const stylesDesktop = {
    button: {
        background: ThemeColors.themePrimary,
        border: 'none',
        borderRadius: 5,
        color: 'white',
        paddingTop: '0.8vw',
        paddingBottom: '0.8vw',
        paddingLeft: '1.5vw',
        paddingRight: '1.5vw',
        marginRight: '4vw',
        fontSize: '1.2vw',
        fontWeight: 800,
        fontFamily: "'Ubuntu', sans-serif",
    },
}

const stylesMobile = {
    button: {
        background: ThemeColors.themePrimary,
        border: 'none',
        borderRadius: '0.6vh',
        color: 'white',
        paddingTop: '1vh',
        paddingBottom: '1vh',
        paddingLeft: '2vh',
        paddingRight: '2vh',
        marginRight: '4vh',
        fontSize: '2vh',
        fontFamily: "'Ubuntu', sans-serif",
    },
}
