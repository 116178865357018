import React from 'react'
import {useHistory} from 'react-router-dom'
import {isDesktop} from 'react-device-detect'
import {ThemeColors} from '../../../Constants'

interface Props {
    setPage: (page: string) => void
}

export default function (props: Props) {
    const styles = isDesktop ? stylesDesktop : stylesMobile

    const history = useHistory()
    return (
        <div style={styles.buttonContainer}>
            <button
                style={styles.button}
                onClick={() => {
                    props.setPage('score')
                }}>
                Back
            </button>
            <button
                style={styles.button}
                onClick={() => {
                    history.push('/')
                }}>
                Home
            </button>
        </div>
    )
}

const stylesDesktop = {
    buttonContainer: {
        position: 'fixed' as 'fixed',
        top: '1.2vw',
        right: '0.1vw',
        alignItems: 'center' as 'center',
        marginRight: '3vw',
    },
    button: {
        background: ThemeColors.themePrimary,
        border: 'none',
        borderRadius: 5,
        color: 'white',
        paddingTop: '1vw',
        paddingBottom: '1vw',
        paddingLeft: '2vw',
        paddingRight: '2vw',
        marginRight: '1.2vw',
        fontSize: '1.2vw',
        fontWeight: 800,
        fontFamily: "'Ubuntu', sans-serif",
    },
}

const stylesMobile = {
    buttonContainer: {
        position: 'fixed' as 'fixed',
        right: '2vh',
        top: '2vh',
        alignItems: 'center' as 'center',
        marginRight: '2vh',
        display: 'flex',
        flexDirection: 'column' as 'column',
    },
    button: {
        background: ThemeColors.themePrimary,
        border: 'none',
        borderRadius: '0.6vh',
        color: 'white',
        paddingTop: '1vh',
        paddingBottom: '1vh',
        paddingLeft: '2vh',
        paddingRight: '2vh',
        fontSize: '2vh',
        fontFamily: "'Ubuntu', sans-serif",
        marginBottom: '2vh',
        width: '10vh',
    },
}
