import * as React from 'react'
import {ThemeColors} from '../../../Constants'

interface Props {
    submitTest: () => void
}

export default function (props: Props) {
    return (
        <button style={styles.button} onClick={props.submitTest}>
            Finish
        </button>
    )
}

const styles = {
    button: {
        background: ThemeColors.themePrimary,
        border: 'none',
        borderRadius: 5,
        color: 'white',
        paddingTop: '0.8vw',
        paddingBottom: '0.8vw',
        paddingLeft: '1.5vw',
        paddingRight: '1.5vw',
        marginRight: '4vw',
        fontSize: '1.2vw',
        fontWeight: 800,
        fontFamily: "'Ubuntu', sans-serif",
        alignSelf: 'flex-start' as 'flex-start',
    },
}
