import React from 'react'
import {QuestionManipulator} from '../../../types/Question'
// @ts-ignore
import MathJax from 'react-mathjax2'
import {isDesktop} from 'react-device-detect'
import {ThemeColors} from '../../../Constants'

interface Props {
    text: string
    answer: string
}

const imageSrc = 'https://neetbio-images.s3.ap-south-1.amazonaws.com/'

export default function (props: Props) {
    const styles = isDesktop ? stylesDesktop : stylesMobile

    return (
        <>
            <div style={styles.answerHeading}>
                {props.text}
                <br /> Answer
            </div>
            <div style={styles.answer}>
                {props.answer.search && props.answer.search('golden-eye-marquis-') > -1 ? (
                    <img src={imageSrc + props.answer.replace('golden-eye-marquis-', '')} />
                ) : (
                    QuestionManipulator.formatText(props.answer)
                )}
            </div>
        </>
    )
}

const stylesDesktop = {
    answerHeading: {
        fontSize: '1.2vw',
        color: 'orange',
        fontWeight: 600,
        marginRight: '1.2vw',
        fontFamily: "'Oswald', sans-serif",
    },
    answer: {
        fontSize: '1.2vw',
        color: 'white',
        borderRadius: 5,
        backgroundColor: ThemeColors.themeTertiary,
        paddingTop: '0.6vw',
        paddingBottom: '0.6vw',
        paddingLeft: '1vw',
        paddingRight: '1vw',
        marginRight: '3vw',
        display: 'flex',
        alignItems: 'center' as 'center',
    },
}

const stylesMobile = {
    answerHeading: {
        fontSize: '2vh',
        color: 'orange',
        fontWeight: 600,
        marginRight: '2vh',
        fontFamily: "'Oswald', sans-serif",
    },
    answer: {
        fontSize: '2vh',
        color: 'white',
        borderRadius: '1vh',
        backgroundColor: ThemeColors.themeTertiary,
        paddingTop: '1vh',
        paddingBottom: '1vh',
        paddingLeft: '2vh',
        paddingRight: '2vh',
        marginRight: '2vh',
        display: 'flex',
        alignItems: 'center' as 'center',
    },
}
