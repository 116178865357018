import React, {useEffect, useState} from 'react'
import TestService from '../../services/TestService'
import Test from '../../types/Test'
import ControlPanel from './ControlPanel/ControlPanel'
import QuestionRenderer from './QuestionRenderer'
import {useHistory} from 'react-router-dom'
import Header from './Header'
import {ThemeColors} from '../../Constants'
import {isDesktop} from 'react-device-detect'

interface Props {
    name: string
    mobileNumber: string
    testData: Test
    setTestData: (testData: any) => void
}

export default function (props: Props) {
    const history = useHistory()
    const [questionNumber, setQuestionNumber] = useState(0)
    const [time, setTime] = useState(1.5 * 3600)
    const [interval, setInterval] = useState(null)

    const submitTest = () => {
        console.log('Submit Called')
        console.log({...props.testData, completed: true})
        props.setTestData({...props.testData, completed: true})
        TestService.saveTest(props.name, props.mobileNumber, {
            ...props.testData,
            timeTaken: 3 * 3600 - time,
        }).catch(err => {
            alert(err.message)
        })
        history.push('/result')
    }

    useEffect(() => {
        if (interval) {
            // @ts-ignore
            window.clearInterval(interval)
        }
        console.log(props.testData.questionList.length, props.testData.questionList)
        const currentInterval = window.setInterval(() => {
            setTime(time => time - 1)
        }, 1000)
        // @ts-ignore
        setInterval(currentInterval)
        // @ts-ignore
    }, [])

    useEffect(() => {
        if (!time) {
            submitTest()
        }
        // @ts-ignore
    }, [time])

    return (
        <div style={styles.container}>
            <Header
                name={props.name}
                time={time}
                mobileNumber={props.mobileNumber}
                questionNumber={questionNumber}
                setTestData={props.setTestData}
                testData={props.testData}
            />
            <QuestionRenderer
                question={props.testData.questionList[questionNumber]}
                questionInstance={props.testData.questionInstanceList[questionNumber]}
                selectAnswer={(answer: string) => {
                    const newTestData: Test = props.testData
                    newTestData.questionInstanceList[questionNumber].selectedAnswer = answer.toString()
                    console.log(newTestData.questionInstanceList[questionNumber].selectedAnswer)
                    props.setTestData(newTestData)
                }}
            />
            <ControlPanel
                questionInstanceList={props.testData.questionInstanceList}
                currentQuestionNumber={questionNumber}
                nextQuestion={() => {
                    setQuestionNumber(Math.min(questionNumber + 1, 89))
                }}
                previousQuestion={() => {
                    setQuestionNumber(Math.max(questionNumber - 1, 0))
                }}
                submitTest={submitTest}
            />
        </div>
    )
}

const styles = {
    container: {
        minHeight: '100%',
        backgroundImage: isDesktop ? 'url(./test/bg.png)' : '',
        backgroundColor: ThemeColors.themeSecondary,
        backgroundSize: 'cover',
        display: 'flex',
        flexDirection: 'column' as 'column',
        flex: 1,
        justifyContent: 'space-between',
    },
    body: {
        minHeight: '120%',
        display: 'flex',
        backgroundColor: ThemeColors.themeSecondary,
        flex: 1,
        flexDirection: 'row' as 'row',
        justifyContent: 'space-between',
    },
}
