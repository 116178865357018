import React from 'react'
import {isDesktop} from 'react-device-detect'
import {ThemeColors} from '../../../Constants'

interface Props {
    supportImage: string
}

const imageSrc = 'https://neetbio-images.s3.ap-south-1.amazonaws.com/'

export default function (props: Props) {
    const styles = isDesktop ? stylesDesktop : stylesMobile

    return (
        <div style={styles.supportImageContainer}>
            {props.supportImage && props.supportImage.length ? (
                <div style={styles.supportImage}>
                    <img src={imageSrc + props.supportImage} />
                </div>
            ) : (
                <div />
            )}
        </div>
    )
}

const stylesDesktop = {
    supportImage: {
        backgroundColor: ThemeColors.themeTertiary,
        padding: '1vw',
        marginLeft: '1.5vw',
        borderRadius: 5,
        height: 'fit-content',
        display: 'flex',
        flexDirection: 'column' as 'column',
        alignSelf: 'flex-start',
    },
    supportImageContainer: {
        display: 'flex',
        marginBottom: '1.2vw',
    },
}

const stylesMobile = {
    supportImage: {
        backgroundColor: ThemeColors.themeTertiary,
        padding: '2vh',
        marginLeft: '3vh',
        borderRadius: '1vh',
        height: 'fit-content',
        display: 'flex',
        flexDirection: 'column' as 'column',
        alignSelf: 'flex-start',
        maxWidth: '100%',
    },
    supportImageContainer: {
        display: 'flex',
        marginBottom: '2vh',
    },
}
