import {TestManipulator} from '../types/Test'
import Test from '../types/Test'
import TestResult from '../types/TestResult'
import NetworkManager from '../infrastructure/NetworkManager'
import RawTest from '../types/RawTest'
import Question, {QuestionManipulator} from '../types/Question'
import QuestionInstance from "../types/QuestionInstance";

class TestService {
    async fetchTest(mobileNumber: string, name: string, setTestData: (testData: any) => void, history: any) {
        if (!/^\d{10}$/.test(mobileNumber)) {
            alert('Is that your real mobile number?')
        } else if (name.length < 3) {
            alert('Wow you have a really small name')
        } else {
            const testData = await NetworkManager.fetch('/test/fetch', {
                mobileNumber,
                name,
            })
            const formattedTestData: RawTest = {
                ...testData,
                questionList: testData.questionList.map((question: Question) => QuestionManipulator.formatQuestion(question)),
            }
            setTestData(TestManipulator.createNewTestInstance(formattedTestData))
            history.push('/test')
        }
    }

    async saveTest(name: string, mobileNumber: string, test: Test): Promise<void> {
        const testResult: TestResult = {
            id: test.id,
            score: test.score,
            timeTaken: test.questionInstanceList
                .map((question: QuestionInstance) => question.timeTaken)
                .reduce((curr: number, acc: number) => curr + acc),
            questionInstanceList: test.questionInstanceList,
            timestamp: 0
        }
        testResult.questionInstanceList = testResult.questionInstanceList
            .map((questionInstance: QuestionInstance, index: number): QuestionInstance => {
                if (test.questionList[index].answer === questionInstance.selectedAnswer) {
                    return { ...questionInstance, correct: true }
                } else {
                    return questionInstance
                }
            })
        await NetworkManager.fetchVoid('/test/save', { mobileNumber, test: testResult, name })
    }
}

export default new TestService()
