import React from 'react'
import {useHistory} from 'react-router-dom'
import {isDesktop} from 'react-device-detect'
import TestService from '../../services/TestService'
import {ThemeColors} from '../../Constants'

interface Props {
    mobileNumber: string
    name: string
    setMobileNumber: (mobileNumber: string) => void
    setName: (emailAddress: string) => void
    setTestData: (testData: any) => void
    loading: boolean
    setLoading: (loading: boolean) => void
}

export default function (props: Props) {
    const history = useHistory()
    const styles = isDesktop ? stylesDesktop : stylesMobile
    return (
        <div style={styles.form}>
            <div style={styles.formContainer}>
                <div style={styles.formHeading}>Get Tested</div>
                <input
                    style={styles.input}
                    value={props.name}
                    placeholder={'Name'}
                    onChange={e => {
                        // @ts-ignore
                        props.setName(e.target.value)
                    }}
                />
                <input
                    style={styles.input}
                    value={props.mobileNumber}
                    placeholder={'Mobile Number'}
                    onChange={e => {
                        // @ts-ignore
                        props.setMobileNumber(e.target.value)
                    }}
                />
                <button
                    style={styles.button}
                    onClick={() => {
                        props.setLoading(true)
                        TestService.fetchTest(props.mobileNumber, props.name, props.setTestData, history).then(() =>
                            props.setLoading(false),
                        )
                    }}>
                    Start Test
                </button>
            </div>
        </div>
    )
}

const stylesDesktop = {
    input: {
        paddingTop: '1vw',
        paddingBottom: '1vw',
        paddingLeft: '1vw',
        paddingRight: '1vw',
        marginBottom: '1.2vw',
        width: '24vw',
        borderRadius: '1vh',
        fontSize: '1.2vw',
        fontFamily: "'Ubuntu', sans-serif",
        fontWeight: 800,
        border: 'none' as 'none',
    },
    button: {
        background: ThemeColors.themePrimary,
        border: 'none',
        borderRadius: '1vh',
        color: 'white',
        paddingTop: '1vw',
        paddingBottom: '1vw',
        paddingLeft: '2vw',
        paddingRight: '2vw',
        marginTop: '2.4vw',
        fontSize: '1.2vw',
        fontWeight: 800,
        fontFamily: "'Ubuntu', sans-serif",
    },
    form: {
        alignSelf: 'center',
        flex: 1,
        display: 'flex',
        flexDirection: 'column' as 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        margin: '3vw',
    },
    formHeading: {
        color: 'white',
        fontFamily: "'Open Sans', sans-serif",
        fontSize: '3.4vw',
        fontWeight: 200,
        marginBottom: '2.4vw',
    },
    formContainer: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        alignItems: 'center',
    },
    promo: {
        color: 'white',
        marginTop: '1.5vw',
        fontSize: '1.2vw',
        fontWeight: 600,
        border: '2px solid white',
        borderRadius: '1vh',
        padding: '3vh',
    },
}

const stylesMobile = {
    promo: {
        color: 'white',
        marginTop: '8vw',
        fontSize: '4vw',
        border: '2px solid white',
        fontWeight: 600,
        borderRadius: '2vw',
        padding: '4vw',
    },
    input: {
        paddingTop: '4vw',
        paddingBottom: '4vw',
        paddingLeft: '4vw',
        paddingRight: '4vw',
        marginBottom: '6vw',
        width: '70vw',
        borderRadius: '2vw',
        fontSize: '4vw',
        fontFamily: "'Ubuntu', sans-serif",
        fontWeight: 800,
        border: 'none' as 'none',
    },
    button: {
        background: ThemeColors.themePrimary,
        border: 'none',
        borderRadius: '2vw',
        color: 'white',
        paddingTop: '4vw',
        paddingBottom: '4vw',
        paddingLeft: '4vw',
        paddingRight: '4vw',
        marginTop: '8vw',
        fontSize: '4vw',
        fontWeight: 800,
        fontFamily: "'Ubuntu', sans-serif",
    },
    form: {
        alignSelf: 'center',
        flex: 1,
        display: 'flex',
        flexDirection: 'column' as 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    formHeading: {
        color: 'white',
        fontFamily: "'Open Sans', sans-serif",
        fontSize: '10vw',
        fontWeight: 200,
        marginBottom: '8vw',
    },
    formContainer: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        alignItems: 'center',
        width: '100%',
    },
    terms: {color: 'white', fontSize: '4vw'},
}
