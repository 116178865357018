import React from 'react'
import TestService from '../../services/TestService'
import Test from '../../types/Test'
import {useHistory} from 'react-router-dom'
import {isDesktop} from 'react-device-detect'
import {ThemeColors} from '../../Constants'

interface Props {
    name: string
    mobileNumber: string
    testData: Test
    setTestData: (testData: any) => void
    questionNumber: number
    time: number
}

export default function (props: Props) {
    const history = useHistory()

    const submitTest = () => {
        props.setTestData({...props.testData, completed: true})
        TestService.saveTest(props.name, props.mobileNumber, {
            ...props.testData,
            timeTaken: 3 * 3600 - props.time,
        }).catch(err => {
            alert(err.message)
        })
        history.push('/result')
    }

    if (isDesktop) {
        const styles = stylesDesktop
        return (
            <div style={styles.container}>
                <div style={styles.questionNumberContainer}>
                    <div style={styles.currentQuestionNumber}>{props.questionNumber + 1}</div>
                    <div style={styles.totalQuestions}>/ 90</div>
                </div>
                <div style={styles.timer}>{new Date(props.time * 1000).toISOString().substr(11, 8)}</div>
                <div style={styles.topBgImageContainer}>
                    <div
                        style={{
                            color: 'white',
                            fontSize: '2.4vw',
                            fontWeight: 600,
                            padding: '1vw',
                            fontFamily: "'Ubuntu', sans-serif",
                        }}>
                        NeetBio
                    </div>
                </div>
            </div>
        )
    } else {
        const styles = stylesMobile
        return (
            <div style={styles.container}>
                <div style={styles.topRow}>
                    <div style={styles.questionNumberContainer}>
                        <div style={styles.currentQuestionNumber}>{props.questionNumber + 1}</div>
                        <div style={styles.totalQuestions}>/ 90</div>
                    </div>
                    <div style={styles.logo}>NeetBio</div>
                </div>
                <div style={styles.bottomRow}>
                    <button style={styles.button} onClick={submitTest}>
                        Finish
                    </button>
                    <div style={styles.timer}>{new Date(props.time * 1000).toISOString().substr(11, 8)}</div>
                </div>
            </div>
        )
    }
}

const stylesDesktop = {
    container: {
        display: 'flex',
        marginTop: '2vw',
        marginRight: '2vw',
        flexDirection: 'row' as 'row',
        justifyContent: 'space-between',
        alignItems: 'center' as 'center',
    },
    body: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row' as 'row',
        justifyContent: 'space-between',
    },
    questionNumberContainer: {
        display: 'flex',
        flexDirection: 'row' as 'row',
        alignItems: 'baseline',
        paddingLeft: '3vw',
        flex: 1,
    },
    currentQuestionNumber: {
        fontSize: '4vw',
        fontWeight: 700,
        color: 'white',
        paddingRight: '1.2vw',
    },
    timer: {
        color: 'white',
        fontSize: '2vw',
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        fontFamily: "Teko, 'Normal'",
    },
    totalQuestions: {fontSize: '2.4vw', fontWeight: 700, color: 'white'},
    topBgImageContainer: {display: 'flex', flex: 1, justifyContent: 'flex-end'},
    topBgImage: {height: '8vw'},
}

const stylesMobile = {
    container: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        justifyContent: 'space-between',
    },
    topRow: {
        padding: '1vh',
        // backgroundColor: ThemeColors.themePrimary,
        display: 'flex',
        flex: 1,
        flexDirection: 'row' as 'row',
        justifyContent: 'space-between',
        alignItems: 'baseline',
        width: '100%',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right',
    },
    questionNumberContainer: {
        display: 'flex',
        flexDirection: 'row' as 'row',
        alignItems: 'baseline',
        paddingLeft: '3vw',
        flex: 1,
    },
    currentQuestionNumber: {
        fontSize: '5vh',
        fontWeight: 700,
        color: 'white',
        paddingRight: '1.2vw',
    },
    timer: {
        color: 'white',
        fontSize: '3vh',
        display: 'flex',
        flex: 1,
        justifyContent: 'flex-end',
        marginRight: '1vh',
        fontFamily: "Teko, 'Normal'",
        letterSpacing: 2,
    },
    totalQuestions: {fontSize: '3vh', fontWeight: 700, color: 'white'},
    bottomRow: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row' as 'row',
        paddingLeft: '2.5vh',
        paddingRight: '1.2vh',
        marginTop: '1vh',
        alignItems: 'flex-end',
    },
    button: {
        background: ThemeColors.themePrimary,
        border: 'none',
        borderRadius: '0.5vh',
        color: 'white',
        paddingTop: '1vh',
        paddingBottom: '1vh',
        paddingLeft: '2vh',
        paddingRight: '2vh',
        marginRight: '4vh',
        fontSize: '2vh',
        fontFamily: "'Ubuntu', sans-serif",
    },
    logo: {
        color: 'white',
        fontSize: '3vh',
        fontWeight: 600,
        marginRight: '1vh',
        fontFamily: "'Ubuntu', sans-serif",
    },
}
