import React from 'react'
import {ThemeColors} from '../../../Constants'

interface Props {
    score: number
    correctCount: number
    incorrectCount: number
    unansweredCount: number
    setPage: () => void
    setHistory: (page: string) => void
}

export default function (props: Props) {
    return (
        <div style={styles.container}>
            <div style={styles.heading}>You Scored</div>
            <div style={styles.scoreContainer}>
                <div style={styles.score}>{props.score.toString() + ' / '}</div>
                <div style={styles.total}> 360</div>
            </div>
            <div style={styles.statusBlock}>
                <div style={styles.statusContainer}>
                    <div style={styles.statusHeading}>CORRECT : </div>
                    <div style={styles.status}>{props.correctCount}</div>
                </div>
                <div style={styles.statusContainer}>
                    <div style={styles.statusHeading}>INCORRECT : </div>
                    <div style={styles.status}>{props.incorrectCount}</div>
                </div>
                <div style={styles.statusContainer}>
                    <div style={styles.statusHeading}>UNANSWERED : </div>
                    <div style={styles.status}>{props.unansweredCount}</div>
                </div>
            </div>
            <div style={styles.buttonContainer}>
                <button onClick={props.setPage} style={styles.button}>
                    Answer Key
                </button>
                <button
                    onClick={() => {
                        props.setHistory('/')
                    }}
                    style={styles.button}>
                    Back to Home
                </button>
            </div>
        </div>
    )
}

const styles = {
    container: {
        padding: '2vh',
        height: '100%',
        backgroundImage: 'url(./result/score/bg-mobile.png)',
        backgroundSize: 'cover',
        display: 'flex',
        flexDirection: 'column' as 'column',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'space-evenly',
    },
    scoreBlock: {},
    heading: {
        color: 'white',
        fontSize: '6vh',
        fontWeight: 700,
        marginBottom: '-2vh',
        paddingBottom: 0,
    },
    scoreContainer: {
        display: 'flex',
        flexDirection: 'row' as 'row',
        alignItems: 'flex-end',
        marginBottom: '6vh',
    },
    score: {
        marginTop: 0,
        color: 'white',
        fontSize: '7vh',
        fontWeight: 800,
    },
    total: {
        color: 'white',
        fontSize: '5vh',
        fontWeight: 800,
        paddingLeft: '0.6vh',
    },
    buttonContainer: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row' as 'row',
        justifyContent: 'space-evenly',
        marginTop: 0,
    },
    button: {
        background: ThemeColors.themePrimary,
        border: 'none',
        borderRadius: '0.6vh',
        color: 'white',
        paddingTop: '1vh',
        paddingBottom: '1vh',
        paddingLeft: '2vh',
        paddingRight: '2vh',
        marginTop: '4vh',
        fontSize: '2.2vh',
        fontWeight: 600,
        fontFamily: "'Ubuntu', sans-serif",
    },
    statusContainer: {
        display: 'flex',
        flexDirection: 'row' as 'row',
        alignItems: 'baseline' as 'baseline',
        marginBottom: '4vh',
    },
    statusHeading: {
        color: 'white',
        fontSize: '3vh',
        letterSpacing: '0.4vh',
        fontWeight: 600,
        fontFamily: "'Ubuntu', sans-serifs",
    },
    status: {
        color: 'white',
        fontSize: '4vh',
        fontWeight: 800,
        marginLeft: '1vh',
    },
    statusBlock: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column' as 'column',
    },
}
