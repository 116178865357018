import React from 'react'
import {isDesktop} from 'react-device-detect'

export default function () {
    if (isDesktop) {
        return (
            <div style={styles.explanationBlock}>
                <div style={styles.explanationItem}>
                    <div style={styles.explanationHighlight}>90</div>
                    <div style={styles.explanationText}>
                        MCQs <br /> Minutes
                    </div>
                </div>
                <div style={{...styles.explanationItem, alignSelf: 'flex-start'}}>
                    <div style={styles.explanationHighlight}>Q</div>
                    <div style={styles.explanationText}>
                        Different set of questions
                        <br /> every time from
                        <br /> a question bank of
                        <br /> 10,000+ questions
                    </div>
                </div>
                <div style={styles.explanationItem}>
                    <div style={styles.explanationHighlight}>K</div>
                    <div style={styles.explanationText}>
                        Immediate
                        <br />
                        Results with
                        <br />
                        Answer Key
                    </div>
                </div>
            </div>
        )
    }
    return <div />
}

const styles = {
    explanationBlock: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        flex: 1,
    },
    explanationItem: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row' as 'row',
        alignItems: 'center' as 'center',
    },
    explanationHighlight: {
        borderRadius: '100vh',
        width: '8vw',
        textAlign: 'center' as 'center',
        height: '8vw',
        backgroundColor: '#F85B07',
        color: 'white',
        fontSize: '4.5vw',
        fontWeight: 800,
    },
    explanationText: {
        color: 'white',
        padding: '1vw',
        fontWeight: 600,
        fontSize: '1.2vw',
    },
}
