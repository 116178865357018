import QuestionContent, {MCQ} from './QuestionContent'
import QuestionType from './QuestionType'
import React from 'react'
// @ts-ignore
import MathJax from 'react-mathjax2'

export default interface Question {
    id: string
    content: QuestionContent
    answer: string
    typeId: QuestionType
}

export class QuestionManipulator {
    static formatQuestion(question: Question): Question {
        if (question.typeId === 'MCQ' || 1) {
            return {
                ...question,
                content: {
                    ...question.content,
                    choices: (question.content as MCQ).choices
                        .map(a => ({sort: Math.random(), value: a}))
                        .sort((a, b) => a.sort - b.sort)
                        .map(a => a.value),
                },
            }
        } else {
            return question
        }
    }

    static formatText(fullText: string) {
        return fullText.split('<br>').map(text => (
            <MathJax.Context>
                <MathJax.Text text={text} />
            </MathJax.Context>
        ))
    }
}
