import React, {useEffect, useState} from 'react'
import Question from '../../types/Question'
import TestScreen from '../test/TestScreen'
import Test, {TestManipulator} from '../../types/Test'
import InternalService from '../../services/InternalService'

export default function () {
    const [questionList, setQuestionList] = useState<Question[]>([])
    const [offset, setOffset] = useState<number>(0)
    const [length, setLength] = useState<number>(0)
    const [test, setTest] = useState<Test | null>(null)

    useEffect(() => {
        setTest(
            TestManipulator.createNewTestInstance({
                id: '1',
                questionList,
            }),
        )
    }, [questionList])

    return (
        <div>
            <input
                onChange={event => {
                    setOffset(parseInt(event.target.value))
                }}
            />
            <input
                onChange={event => {
                    setLength(parseInt(event.target.value))
                }}
            />
            <button
                onClick={async () => {
                    setQuestionList(await InternalService.fetchQuestions(offset, length))
                }}>
                Fetch!
            </button>
            {!!test && test.questionList.length && (
                <TestScreen name={''} testData={test} setTestData={setTest} mobileNumber={''}/>
            )}
        </div>
    )
}
