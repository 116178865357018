import React, {useState} from 'react'
import Form from './Form'
import {isDesktop} from 'react-device-detect'
import Explanation from './Explanation'
import Loading from '../loading'

interface Props {
    mobileNumber: string
    name: string
    setMobileNumber: (mobileNumber: string) => void
    setName: (emailAddress: string) => void
    setTestData: (testData: any) => void
}

export default function (props: Props) {
    const [loading, setLoading] = useState(false)
    if (!loading) {
        return (
            <div
                style={{
                    ...styles.container,
                    backgroundImage: isDesktop ? 'url(./landing/bg.png)' : 'url(./landing/bg-mobile.png)',
                }}>
                {!isDesktop ? <Logo /> : <div />}
                <div style={styles.body}>
                    <Form
                        setTestData={props.setTestData}
                        mobileNumber={props.mobileNumber}
                        setName={props.setName}
                        setMobileNumber={props.setMobileNumber}
                        name={props.name}
                        loading={loading}
                        setLoading={setLoading}
                    />
                    <Explanation />
                    {isDesktop ? <Logo /> : <div />}
                </div>
            </div>
        )
    } else {
        return <Loading />
    }
}

const Logo = () => (
    <div style={styles.logoContainer}>
        <div style={styles.logo}>NeetBio</div>
    </div>
)

const styles = {
    logoContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    logo: {
        color: 'white',
        fontSize: '5vw',
        fontWeight: 700,
        padding: '3vw',
        fontFamily: "'Ubuntu', sans-serif",
    },
    container: {
        padding: 0,
        minHeight: '100%',
        backgroundImage: 'url(./landing/bg.png)',
        backgroundSize: 'cover',
        display: 'flex',
        flexDirection: 'column' as 'column',
        flex: 1,
        justifyContent: 'space-between',
    },
    body: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row' as 'row',
        justifyContent: 'space-between',
    },
}
