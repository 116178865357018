import React from 'react'
import Question from '../../../types/Question'
import {MCQ} from '../../../types/QuestionContent'
// @ts-ignore
import MathJax from 'react-mathjax2'
import QuestionInstance from '../../../types/QuestionInstance'
import {isDesktop} from 'react-device-detect'
import {ThemeColors} from '../../../Constants'

interface Props {
    question: Question
    questionInstance: QuestionInstance
    selectAnswer: (answer: string) => void
}

const imageSrc = 'https://neetbio-images.s3.ap-south-1.amazonaws.com/'

const formatText = (fullText: string) => {
    try {
        return fullText.split('<br>').map((text: string) => (
            <MathJax.Context>
                <MathJax.Text text={text} />
            </MathJax.Context>
        ))
    } catch (e) {}
}

export default function (props: Props) {
    const styles = isDesktop ? stylesDesktop : stylesMobile

    const selectAnswer = (index: number) => {
        props.selectAnswer((props.question.content as MCQ).choices[index].toString())
    }
    const choices: string[] = (props.question.content as MCQ).choices
    const shuffledChoices: string[] = choices

    return (
        <div style={styles.container}>
            <div>
                <div style={styles.question}>
                    <span>{formatText((props.question.content as MCQ).question)}</span>
                </div>
                {(props.question.content as MCQ).supportImage &&
                (props.question.content as MCQ).supportImage.length &&
                !isDesktop ? (
                    <div style={styles.supportImage}>
                        <img src={imageSrc + (props.question.content as MCQ).supportImage} />
                    </div>
                ) : (
                    <div />
                )}
                <div style={styles.choices}>
                    {choices.map((choice: string, index: number) => (
                        <div
                            onClick={() => {
                                selectAnswer(index)
                            }}
                            style={
                                props.questionInstance.selectedAnswer.toString() === shuffledChoices[index].toString()
                                    ? styles.selectedChoiceContainer
                                    : styles.choiceContainer
                            }>
                            {choice.search && choice.search('golden-eye-marquis-') > -1 ? (
                                <img
                                    onClick={() => {
                                        selectAnswer(index)
                                    }}
                                    src={imageSrc + choice.replace('golden-eye-marquis-', '')}
                                />
                            ) : (
                                <div
                                    onClick={() => {
                                        selectAnswer(index)
                                    }}
                                    style={styles.choice}>
                                    {formatText(choice.toString())}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
            {(props.question.content as MCQ).supportImage &&
            (props.question.content as MCQ).supportImage.length &&
            isDesktop ? (
                <div style={styles.supportImage}>
                    {/*<div style={styles.supportImageHeading}>Support Image</div>*/}
                    <img src={imageSrc + (props.question.content as MCQ).supportImage} />
                </div>
            ) : (
                <div />
            )}
        </div>
    )
}

const stylesDesktop = {
    question: {
        fontSize: '1.5vw',
        color: 'white',
        marginBottom: '3vw',
    },
    choice: {
        color: 'white',
        fontSize: '1.5vw',
    },
    container: {
        padding: '4.5vw',
        paddingTop: '3vw',
        paddingBottom: '2vw ',
        display: 'flex',
        flexDirection: 'row' as 'row',
        justifyContent: 'space-between',
    },
    choiceContainer: {
        backgroundColor: ThemeColors.themeTertiary,
        paddingTop: '0.8vw',
        paddingBottom: '0.8vw',
        paddingLeft: '1vw',
        paddingRight: '1vw',
        marginTop: '2vw',
        borderRadius: 5,
        display: 'flex',
        flexDirection: 'row' as 'row',
        alignItems: 'center',
    },
    selectedChoiceContainer: {
        // backgroundColor: '#374249',
        paddingTop: '0.8vw',
        paddingBottom: '0.8vw',
        paddingLeft: '1vw',
        paddingRight: '1vw',
        marginTop: '2vw',
        borderRadius: 5,
        display: 'flex',
        flexDirection: 'row' as 'row',
        alignItems: 'center',
        border: '5px solid #7f8289',
    },
    choices: {
        width: 'fit-content',
    },
    choiceButton: {
        height: '1.5vw',
        width: '1.5vw',
        marginRight: '0.6vw',
    },
    supportImage: {
        backgroundColor: ThemeColors.themeTertiary,
        padding: '1vw',
        marginLeft: '1.5vw',
        borderRadius: 5,
        height: 'fit-content',
        display: 'flex',
        flexDirection: 'column' as 'column',
    },
    supportImageHeading: {
        color: 'white',
        fontSize: '1.5vw',
        marginBottom: '0.6vw',
        alignSelf: 'center',
    },
}

const stylesMobile = {
    question: {
        fontSize: '2.5vh',
        color: 'white',
        marginBottom: '3vh',
    },
    choice: {
        color: 'white',
        fontSize: '2.5vh',
    },
    container: {
        padding: '4.5vw',
        paddingTop: '3vh',
        paddingBottom: '2vh',
        display: 'flex',
        flexDirection: 'row' as 'row',
        justifyContent: 'space-between',
    },
    choiceContainer: {
        backgroundColor: ThemeColors.themeTertiary,
        paddingTop: '1vh',
        paddingBottom: '1vh',
        paddingLeft: '2vh',
        paddingRight: '2vh',
        marginTop: '3vh',
        borderRadius: '1vh',
        display: 'flex',
        flexDirection: 'row' as 'row',
        alignItems: 'center',
    },
    selectedChoiceContainer: {
        backgroundColor: ThemeColors.themeTertiary,
        paddingTop: '1vh',
        paddingBottom: '1vh',
        paddingLeft: '2vh',
        paddingRight: '2vh',
        marginTop: '3vh',
        borderRadius: '1vh',
        display: 'flex',
        flexDirection: 'row' as 'row',
        alignItems: 'center',
        border: '0.3vh solid #7f8289',
    },
    choices: {
        width: 'fit-content',
    },
    choiceButton: {
        height: '3vh',
        width: '3vh',
        marginRight: '1vh',
    },
    supportImage: {
        backgroundColor: ThemeColors.themeTertiary,
        padding: '1vw',
        borderRadius: '1vh',
        height: 'fit-content',
        display: 'flex',
        flexDirection: 'column' as 'column',
        maxWidth: '100%',
    },
}
