import React from 'react'
import {isDesktop} from 'react-device-detect'
import ScoreDesktop from './ScoreDesktop'
import ScoreMobile from './ScoreMobile'

interface Props {
    score: number
    correctCount: number
    incorrectCount: number
    unansweredCount: number
    setPage: () => void
    setHistory: (page: string) => void
}

export default function (props: Props) {
    if (isDesktop) {
        return (
            <ScoreDesktop
                score={props.score}
                correctCount={props.correctCount}
                incorrectCount={props.incorrectCount}
                setHistory={props.setHistory}
                setPage={props.setPage}
                unansweredCount={props.unansweredCount}
            />
        )
    } else {
        return (
            <ScoreMobile
                score={props.score}
                correctCount={props.correctCount}
                incorrectCount={props.incorrectCount}
                setHistory={props.setHistory}
                setPage={props.setPage}
                unansweredCount={props.unansweredCount}
            />
        )
    }
}
